import { ReactNode } from "react";
import { Paper } from "@mui/material";

interface Props {
  children: ReactNode;
}

const FormContainer = ({ children }: Props) => {
  return (
    <Paper elevation={1} sx={{ p: 4 }}>
      {children}
    </Paper>
  );
};

export default FormContainer;
