import { useMemo } from "react";
import { useWeb3React } from "@web3-react/core";
import useWeb3 from "hooks/useWeb3";
import { useMutation, useQuery } from "react-query";
import { fetchWhiteList, removeWhiteList } from "services";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";

import { ActionButtons, ButtonRequireWallet, List } from "common/components";

const WhiteList = () => {
  const { account } = useWeb3React();
  const contract = useWeb3();

  const { data, isLoading } = useQuery<any>("whitelists", fetchWhiteList, {
    refetchOnWindowFocus: false,
  });

  const removeMutation = useMutation(removeWhiteList);

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "id",
        headerName: "ID",
      },
      {
        field: "address",
        headerName: "ADDRESS",
        flex: 1,
      },
      { field: "contact", headerName: "CONTACT" },
      { field: "synced", headerName: "SYNCED" },
      { field: "createdAt", headerName: "CREATED AT" },
      {
        field: "toolbar",
        headerName: "",
        renderCell: (params: GridRenderCellParams) => {
          return (
            <ActionButtons
              id={params.row.id}
              removeMutation={removeMutation}
              queryName="whitelists"
            />
          );
        },
      },
    ],
    [removeMutation]
  );

  const sync = async () => {
    // const lists = data.map((item: any) => item = item.address.replace(/'/g, `"`));
    const lists = data.map((item: any) => item.address);
    console.log(lists);
    // const res = await web3.contract.methods.addWhiteList(['0xFBb4928ACA28957f7383eaB2c4A08Fcd161A5DEc', '0xdE0d7a2554b85a72B13D32651e9375BEB91a0A67', '0x55C9307CCCED8d68D23C3cE0121ed6C495BEdFEa']).send({ from: account })
    try {
      const res = await contract.methods
        .addWhiteList(lists)
        .send({ from: account });
      console.log(res);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <List
      title="Whitelist"
      columns={columns}
      rows={data}
      loading={isLoading}
      creatable
      headerButtons={[
        <ButtonRequireWallet onClick={sync} variant="contained">
          Sync
        </ButtonRequireWallet>,
      ]}
    />
  );
};

export default WhiteList;
