import { createReducer } from "redux-act";

import type { IAdmin } from "types";
import { login, logout } from "../actions";

export type AdminState = IAdmin | null;

const reducer = createReducer<AdminState>({}, null);

reducer.on(login, (_, payload) => {
  return payload;
});

reducer.on(logout, (_) => {
  return null;
});

export default reducer;
