import { useFormik } from "formik";
import * as Yup from "yup";
import { Box, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useMutation } from "react-query";
import { useDispatch } from "react-redux";

import useMsg from "hooks/useMsg";
import { setAccessToken } from "utils/auth";
import { login as loginAction } from "redux/actions";
import { login } from "services";

const schema = Yup.object().shape({
  email: Yup.string().required(),
  password: Yup.string().required(),
});

const Login = () => {
  const showMsg = useMsg();
  const dispatch = useDispatch();

  const mutation = useMutation(login);

  const { handleChange, handleSubmit, values, errors, touched } = useFormik({
    validationSchema: schema,
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: async () => {
      mutation.mutate(
        { email: values.email, password: values.password },
        {
          onSuccess({ data }) {
            dispatch(loginAction(data.admin));
            setAccessToken(data.accessToken);
          },
          onError(err: any) {
            showMsg({ type: "error", text: err.message });
          },
        }
      );
    },
  });

  return (
    <>
      <Box
        display="flex"
        width="100vw"
        height="100vh"
        alignItems="center"
        justifyContent="center"
      >
        <Box sx={{ width: 300 }}>
          <TextField
            error={touched.email && errors.email !== undefined}
            autoFocus
            margin="dense"
            label="Email"
            type="email"
            fullWidth
            variant="outlined"
            name="email"
            onChange={handleChange}
          />
          <TextField
            error={touched.password && errors.password !== undefined}
            autoFocus
            margin="dense"
            label="Password"
            type="password"
            fullWidth
            variant="outlined"
            name="password"
            onChange={handleChange}
          />
          <Box sx={{ mt: 1 }}>
            <LoadingButton
              variant="contained"
              fullWidth
              onClick={() => handleSubmit()}
              loading={mutation.isLoading}
            >
              Login
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Login;
