import { useEffect, useState } from "react";
import contractConfig from "constants/config.json";

const Web3 = require("web3/dist/web3.min.js");

const useWeb3 = () => {
  const [contract, setContract] = useState<any>();

  useEffect(() => {
    if (window.ethereum) {
      const web3 = new Web3(window.ethereum);

      const c = new web3.eth.Contract(
        contractConfig.abi as any,
        contractConfig.address
      );
      setContract(c);
    } else {
      console.log("MetaMask not installed!");
    }
  }, []);

  console.log(" in hook ", contract);
  return contract;
};

export default useWeb3;
