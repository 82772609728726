import { useQuery } from "react-query";
import { useParams } from "react-router-dom";

import { Loader } from "common/components";
import HorseForm from "../Form";
import { getHorseById } from "services";

const HorseEdit = () => {
  const { id } = useParams();

  const { data, isLoading } = useQuery("horse", () => getHorseById(id!), {
    refetchOnWindowFocus: false,
  });
  console.log(data);

  if (isLoading) {
    return <Loader />;
  }

  return <HorseForm data={data} />;
};

export default HorseEdit;
