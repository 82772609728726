import { useWeb3React } from "@web3-react/core";
import { LoadingButton, LoadingButtonProps } from "@mui/lab";

import useMsg from "hooks/useMsg";

interface Props extends LoadingButtonProps {
  onClick: () => void;
}

const ButtonWithWallet = ({ onClick, ...rest }: Props) => {
  const showMsg = useMsg();
  const { account } = useWeb3React();

  const onClickEvent = () => {
    if (!account) {
      return showMsg({ type: "error", text: "Wallet is not connected!" });
    }

    onClick();
  };

  return <LoadingButton {...rest} onClick={onClickEvent} />;
};

export default ButtonWithWallet;
