import axios from "axios";

export const login = (body: { email: string; password: string }) => {
  return axios.post("/admin/login", body, { withCredentials: true });
};

export const logout = () => {
  return axios.post("/admin/logout", null, { withCredentials: true });
};

export const refreshToken = async () => {
  const { data } = await axios.post("/admin/refresh", null, {
    withCredentials: true,
  });
  return data;
};

export const fetchHorseList = async () => {
  const { data } = await axios.get("/horse/list");
  return data;
};

export const removeHorse = async (id: string) => {
  const { data } = await axios.post(`/horse/remove/${id}`);
  return data;
};

export const createHorse = async (body: any) => {
  const { data } = await axios.post(`/horse/create`, body);
  return data;
};

export const editHorse = async (body: { id: string; data: any }) => {
  const { data } = await axios.post(`/horse/edit/${body.id}`, body.data);
  return data;
};

export const getHorseById = async (id: string) => {
  const { data } = await axios.get(`/horse/get/${id}`);
  return data;
};

export const fetchWhiteList = async () => {
  const { data } = await axios.get("/whitelist/list");
  return data;
};

export const removeWhiteList = async (id: string) => {
  const { data } = await axios.post(`/whitelist/remove/${id}`);
  return data;
};

export const addWhiteList = async (body: {
  address: string;
  contact: string;
}) => {
  const { data } = await axios.post(`/whitelist/create`, body);
  return data;
};
