import { useEffect, useState } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import MainLayout from "common/layouts/MainLayout";
import { ReduxState } from "redux/reducers";
import { refreshToken } from "services";
import { login, logout } from "redux/actions";
import { setAccessToken } from "utils/auth";
import { Loader } from "common/components";

import Login from "pages/Login";
import Horse from "pages/Horse";
import HorseCreate from "pages/Horse/Create";
import HorseEdit from "pages/Horse/Edit";
import BoxList from "pages/BoxList";
import WhiteList from "pages/WhiteList";
import WhiteListCreate from "pages/WhiteList/Create";
import Settings from "pages/Settings";
import Info from "pages/Info";

interface LocationState {
  from: Location;
}

const PrivateRoute = ({ children }: { children: JSX.Element }) => {
  const currentAdmin = useSelector((state: ReduxState) => state.admin);
  const location = useLocation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!currentAdmin && loading) {
      refreshToken()
        .then((data) => {
          setAccessToken(data.accessToken);
          dispatch(login(data.admin));
          setLoading(false);
        })
        .catch((e) => {
          dispatch(logout());
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, []);

  if (loading) {
    return <Loader />;
  }

  if (currentAdmin) {
    return children;
  }

  return <Navigate to="/login" state={{ from: location }} />;
};

const PublicRoute = ({ children }: { children: JSX.Element }) => {
  const currentAdmin = useSelector((state: ReduxState) => state.admin);
  const location = useLocation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!currentAdmin && loading) {
      refreshToken()
        .then((data) => {
          setAccessToken(data.accessToken);
          dispatch(login(data.admin));
          setLoading(false);
        })
        .catch((e) => {
          dispatch(logout());
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, []);

  if (loading) {
    return <Loader />;
  }

  if (!currentAdmin) {
    return children;
  }

  const state = location.state as LocationState;
  const to = state?.from || "/";

  return <Navigate to={to} />;
};

const R = () => {
  return (
    <Routes>
      <Route
        path="/login"
        element={
          <PublicRoute>
            <Login />
          </PublicRoute>
        }
      />
      <Route
        path="/*"
        element={
          <PrivateRoute>
            <Routes>
              <Route element={<MainLayout />}>
                <Route path="/" element={<Navigate to="/horse" />} />
                <Route path="/horse" element={<Horse />} />
                <Route path="/horse/create" element={<HorseCreate />} />
                <Route path="/horse/edit/:id" element={<HorseEdit />} />
                <Route path="/box" element={<BoxList />} />
                <Route path="/whitelist" element={<WhiteList />} />
                <Route path="/whitelist/create" element={<WhiteListCreate />} />
                <Route path="/setting" element={<Settings />} />
                <Route path="/info" element={<Info />} />
              </Route>
            </Routes>
          </PrivateRoute>
        }
      ></Route>
    </Routes>
  );
};

export default R;
