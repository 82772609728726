import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
// import { composeWithDevTools } from "redux-devtools-extension";

import rootReducer from "../redux/reducers";

const middlewares = [thunk];

const init = (initialState = {}) => {
  const enhancer = applyMiddleware(...middlewares);
  const store = createStore(rootReducer, initialState, enhancer);

  return store;
};

export default init;
