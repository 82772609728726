import { ReactNode } from "react";
import { Box, Typography, Button } from "@mui/material";
import { grey } from "@mui/material/colors";
import { Add as AddIcon } from "@mui/icons-material";
import styled from "@emotion/styled";

interface Props {
  title: string;
  onCreate?: () => void;
  entriesCount?: number;
  buttons?: ReactNode[];
}

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const Header = ({ title, entriesCount, onCreate, buttons }: Props) => {
  return (
    <Box sx={{ mb: 5 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box>
          <Typography variant="h3">{title}</Typography>
          {entriesCount && (
            <Box sx={{ color: grey[600] }}>{entriesCount} entries found</Box>
          )}
        </Box>
        <ButtonContainer>
          {buttons?.map((item, index) => (
            <div key={index}>{item}</div>
          ))}
          {onCreate && (
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={onCreate}
            >
              Create new entry
            </Button>
          )}
        </ButtonContainer>
      </Box>
    </Box>
  );
};

export default Header;
