import { useEffect, useState } from "react";
import {
  faAddressBook,
  faBox,
  faGear,
  faHorseHead,
  faList,
} from "@fortawesome/free-solid-svg-icons";
import { faCopy } from "@fortawesome/free-regular-svg-icons";
import { useNavigate, useLocation } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
  Tooltip,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch } from "react-redux";

import { injected } from "utils/walletConnector";
import { logout } from "services";
import { logout as logoutAction } from "redux/actions";

const Container = styled.div`
  width: 220px;
  height: 100vh;
  position: fixed;
  display: flex;
  flex-direction: column;
`;

const Logo = styled.div`
  width: 100%;
  height: 100px;
  background-image: url(/images/logo.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
`;

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { account, activate } = useWeb3React();
  const [logoutLoading, setLogoutLoading] = useState(false);
  const rootPath = location.pathname.split("/")[1];
  const [copied, setCopied] = useState(false);

  const onCopy = () => {
    setCopied(true);
    navigator.clipboard.writeText(account!);
  };

  const hideCopiedText = () => {
    setCopied(false);
  };

  useEffect(() => {
    const provider = window.localStorage.getItem("provider");
    if (provider) {
      activate(injected);
    }
  }, []);

  const onConnectWallet = async () => {
    if (!window.ethereum) return alert("Not metamask installed");

    try {
      window.localStorage.setItem("provider", "injected");
      await activate(injected);
    } catch (e) {
      console.log(e);
    }
  };

  const onLogout = () => {
    setLogoutLoading(true);
    logout()
      .then(() => {
        dispatch(logoutAction());
      })
      .catch((e) => {
        setLogoutLoading(false);
      });
  };

  return (
    <Box sx={{ width: 220 }}>
      <Container>
        <Logo />
        <List sx={{ flex: 1 }}>
          <ListItem
            button
            onClick={() => navigate("/horse")}
            selected={rootPath === "horse"}
          >
            <ListItemIcon>
              <FontAwesomeIcon icon={faHorseHead} />
            </ListItemIcon>
            <ListItemText primary="Horses" />
          </ListItem>
          <ListItem
            button
            onClick={() => navigate("/box")}
            selected={rootPath === "box"}
          >
            <ListItemIcon>
              <FontAwesomeIcon icon={faBox} />
            </ListItemIcon>
            <ListItemText primary="Boxes" />
          </ListItem>
          <ListItem
            button
            onClick={() => navigate("/whitelist")}
            selected={rootPath === "whitelist"}
          >
            <ListItemIcon>
              <FontAwesomeIcon icon={faAddressBook} />
            </ListItemIcon>
            <ListItemText primary="Whitelists" />
          </ListItem>
          <ListItem
            button
            onClick={() => navigate("/setting")}
            selected={rootPath === "setting"}
          >
            <ListItemIcon>
              <FontAwesomeIcon icon={faGear} />
            </ListItemIcon>
            <ListItemText primary="Settings" />
          </ListItem>
          <ListItem
            button
            onClick={() => navigate("/info")}
            selected={rootPath === "info"}
          >
            <ListItemIcon>
              <FontAwesomeIcon icon={faList} />
            </ListItemIcon>
            <ListItemText primary="Infos" />
          </ListItem>
        </List>
        <Box sx={{ m: 1 }}>
          {account ? (
            <Tooltip
              PopperProps={{
                disablePortal: true,
              }}
              onClose={hideCopiedText}
              open={copied}
              arrow
              disableFocusListener
              disableTouchListener
              title="Copied"
            >
              <Box
                onClick={onCopy}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
              >
                <Box textAlign="center">{`${account.slice(
                  0,
                  8
                )}...${account.slice(
                  account.length - 8,
                  account.length
                )}`}</Box>
                <Box sx={{ ml: 1 }}>
                  <FontAwesomeIcon icon={faCopy} size="lg" />
                </Box>
              </Box>
            </Tooltip>
          ) : (
            <Button onClick={onConnectWallet} variant="contained" fullWidth>
              Connect Wallet
            </Button>
          )}
          <Box sx={{ mt: 2 }}>
            <LoadingButton
              onClick={onLogout}
              variant="contained"
              loading={logoutLoading}
              fullWidth
            >
              Logout
            </LoadingButton>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Sidebar;
