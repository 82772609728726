import { createReducer } from "redux-act";

import type { IMsg } from "types";
import { showMsg, hideMsg } from "../actions";

export type MsgState = IMsg | null;

const reducer = createReducer<MsgState>({}, null);

reducer.on(showMsg, (_, payload) => {
  return { ...payload, open: true };
});

reducer.on(hideMsg, (state: MsgState) => {
  if (state) {
    return { ...state, open: false };
  }
  return null;
});

export default reducer;
