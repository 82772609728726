import type { IBox } from "types";

const boxes: IBox[] = [
  { id: 0, type: "cyber" },
  { id: 1, type: "warrior" },
  { id: 2, type: "unicorn" },
  { id: 3, type: "dragon" },
];

export const boxTypes = boxes.map((i) => i.type);

export default boxes;
