import React from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import { Web3ReactProvider } from "@web3-react/core";
import { Provider } from "react-redux";
import { QueryClientProvider } from "react-query";

import createStore from "utils/store";
import App from "./App";
import { accessToken, setAccessToken } from "utils/auth";
import queryClient from "utils/queryClient";
import { refreshToken } from "services";

const Web3 = require("web3/dist/web3.min.js");

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.request.use(
  function (config: any) {
    if (accessToken) {
      config.headers["Authorization"] = `Bearer ${accessToken}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error: any) {
    if (error.response.status === 401) {
      refreshToken()
        .then((data) => {
          setAccessToken(data.accessToken);
          axios.request(error.config);
        })
        .catch((e) => {
          console.log(e);
        });
    }
    return Promise.reject(error.response.data);
  }
);

const getLibrary = (provider: any) => {
  return new Web3(provider);
};

const store = createStore();

ReactDOM.render(
  <React.StrictMode>
    <Web3ReactProvider getLibrary={getLibrary}>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </Provider>
    </Web3ReactProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
