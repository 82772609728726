import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { Box, TextField } from "@mui/material";
import { useMutation } from "react-query";
import { LoadingButton } from "@mui/lab";

import { FormContainer, Header } from "common/components";
import useMsg from "hooks/useMsg";
import { addWhiteList } from "services";

const schema = Yup.object().shape({
  address: Yup.string().required(),
});

const WhiteListForm = () => {
  const navigate = useNavigate();
  const showMsg = useMsg();

  const mutation = useMutation(addWhiteList);

  const { handleChange, handleSubmit, values, errors, touched } = useFormik({
    validationSchema: schema,
    initialValues: {
      address: "",
      contact: "",
    },
    onSubmit: async () => {
      mutation.mutate(
        { address: values.address, contact: values.contact },
        {
          onSuccess() {
            showMsg({ type: "success", text: "Added" });
            navigate("/whitelist");
          },
          onError(err: any) {
            showMsg({ type: "error", text: err.message });
          },
        }
      );
    },
  });

  return (
    <>
      <Header
        title="Create an entry"
        buttons={[
          <LoadingButton
            variant="contained"
            onClick={() => handleSubmit()}
            loading={mutation.isLoading}
          >
            Save
          </LoadingButton>,
        ]}
      />
      <FormContainer>
        <Box sx={{ display: "flex", gap: 1 }}>
          <TextField
            autoFocus
            margin="dense"
            label="Address"
            type="text"
            fullWidth
            name="address"
            variant="outlined"
            value={values.address}
            onChange={handleChange}
            error={touched.address && errors.address !== undefined}
          />
          <TextField
            autoFocus
            margin="dense"
            label="Contact"
            type="text"
            fullWidth
            name="contact"
            variant="outlined"
            value={values.contact}
            onChange={handleChange}
          />
        </Box>
      </FormContainer>
    </>
  );
};

export default WhiteListForm;
