import { useNavigate } from "react-router-dom";
import { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material";
import useMsg from "hooks/useMsg";
import queryClient from "utils/queryClient";
import { LoadingButton } from "@mui/lab";

interface Props {
  id: string;
  editable?: boolean;
  removeMutation: any;
  queryName: string;
}

const Actions = ({ editable, id, removeMutation, queryName }: Props) => {
  const navigate = useNavigate();
  const [removeModal, setRemoveModal] = useState(false);
  const showMsg = useMsg();

  const showRemoveModal = () => {
    setRemoveModal(true);
  };

  const hideRemoveModal = () => {
    setRemoveModal(false);
  };

  const onRemoveEvent = () => {
    removeMutation.mutate(id, {
      onSuccess(data: any) {
        showMsg({ type: "success", text: "Removed" });
        hideRemoveModal();
        queryClient.setQueryData(queryName, (old: any) => {
          return old.filter((item: any) => item.id !== data.id);
        });
      },
      onError(err: any) {
        showMsg({ type: "error", text: err.message });
        hideRemoveModal();
      },
    });
  };

  return (
    <>
      <Box sx={{ display: "flex" }}>
        {editable && (
          <IconButton
            color="primary"
            aria-label="edit"
            component="span"
            onClick={() => navigate(`edit/${id}`)}
          >
            <EditIcon />
          </IconButton>
        )}
        <IconButton
          color="primary"
          aria-label="edit"
          component="span"
          onClick={showRemoveModal}
        >
          <DeleteIcon />
        </IconButton>
      </Box>
      <Dialog
        open={removeModal}
        onClose={hideRemoveModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to remove this?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={hideRemoveModal}>Cancel</Button>
          <LoadingButton
            onClick={onRemoveEvent}
            autoFocus
            variant="contained"
            loading={removeMutation.isLoading}
          >
            Remove
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Actions;
