import { useMutation, useQuery } from "react-query";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import styled from "@emotion/styled";

import { fetchHorseList, removeHorse } from "services";
import { ActionButtons, List } from "common/components";
import { boxTypes } from "constants/box";
import { useMemo } from "react";

const Img = styled.div<{ src: string }>`
  ${(props) => `
    height: 50px;
    width: 100px;
    background-image: url(${props.src});
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  `}
`;

const HorseList = () => {
  const { data, isLoading } = useQuery("horses", fetchHorseList, {
    refetchOnWindowFocus: false,
  });

  const removeMutation = useMutation(removeHorse);

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "id",
        headerName: "ID",
      },
      {
        field: "image",
        headerName: "IMAGE",
        renderCell: (params: GridRenderCellParams) => {
          return <Img src={params.value} />;
        },
      },
      { field: "name", headerName: "NAME", flex: 1 },
      {
        field: "type",
        headerName: "TYPE",
        renderCell: (params: GridRenderCellParams) => {
          return boxTypes[params.value];
        },
      },
      { field: "level", headerName: "LEVEL" },
      { field: "rarity", headerName: "RARITY" },
      { field: "createdAt", headerName: "CREATED AT" },
      {
        field: "toolbar",
        headerName: "",
        renderCell: (params: GridRenderCellParams) => {
          return (
            <ActionButtons
              id={params.row.id}
              removeMutation={removeMutation}
              queryName="horses"
              editable
            />
          );
        },
      },
    ],
    [removeMutation]
  );

  return (
    <List
      title="Horse"
      columns={columns}
      rows={data}
      loading={isLoading}
      creatable
    />
  );
};

export default HorseList;
