import useWeb3 from "hooks/useWeb3";
import { useWeb3React } from "@web3-react/core";

import { Header, ButtonRequireWallet } from "common/components";

const Settings = () => {
  const contract = useWeb3();

  const { account } = useWeb3React();

  const onSale = async () => {
    try {
      const res = await contract.methods
        .startContract()
        .send({ from: account });
      console.log(" == ", res);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <Header
        title="Setting"
        buttons={[
          <ButtonRequireWallet onClick={onSale} variant="contained">
            Start Sale
          </ButtonRequireWallet>,
        ]}
      />
    </>
  );
};

export default Settings;
