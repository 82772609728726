import { useDispatch } from "react-redux";

import { showMsg } from "redux/actions";
import type { IMsg } from "types";

const useError = () => {
  const dispatch = useDispatch();

  const changeMsg = (msg: IMsg) => {
    dispatch(showMsg(msg));
  };

  return changeMsg;
};

export default useError;
