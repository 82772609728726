import { Outlet } from "react-router-dom";
import { Box } from "@mui/material";
import { grey } from "@mui/material/colors";

import Sidebar from "./Sidebar";

const MainLayout = () => {
  return (
    <Box sx={{ display: "flex" }}>
      <Sidebar />
      <Box
        sx={{
          py: 2,
          px: 4,
          flex: 1,
          background: grey[100],
          minHeight: "100vh",
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
};

export default MainLayout;
