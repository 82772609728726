import { Alert, Snackbar } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import { hideMsg } from "redux/actions";
import { ReduxState } from "redux/reducers";
import Routes from "./routes";

const App = () => {
  const dispatch = useDispatch();
  const msg = useSelector((state: ReduxState) => state.msg);

  const onCloseSnackbar = () => {
    dispatch(hideMsg());
  };

  return (
    <BrowserRouter>
      <Routes />
      <Snackbar
        open={msg?.open}
        autoHideDuration={6000}
        onClose={onCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity={msg?.type}>{msg?.text}</Alert>
      </Snackbar>
    </BrowserRouter>
  );
};

export default App;
