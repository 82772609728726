import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useWeb3React } from "@web3-react/core";
import { useNavigate, useParams } from "react-router-dom";
import {
  TextField,
  MenuItem,
  Select,
  Box,
  InputLabel,
  FormControl,
} from "@mui/material";

import useMsg from "hooks/useMsg";
import {
  ButtonRequireWallet,
  FormContainer,
  Header,
  InputImage,
} from "common/components";
import boxes from "constants/box";
import useWeb3 from "hooks/useWeb3";
import { IHorse } from "types";
import { useMutation } from "react-query";
import { createHorse, editHorse } from "services";

const rarityOptions = [
  { label: "common", value: "common" },
  { label: "rare", value: "rare" },
];

const schema = Yup.object().shape({
  name: Yup.string().required(),
  type: Yup.number().required(),
});

interface Props {
  data?: IHorse;
}

const HorseForm = ({ data }: Props) => {
  const navigate = useNavigate();
  const contract = useWeb3();
  const { account } = useWeb3React();
  const [image, setImage] = useState();
  const { id } = useParams();
  const showMsg = useMsg();

  const mutation = useMutation(id ? editHorse : createHorse);

  const { handleChange, handleSubmit, values, errors, touched } = useFormik({
    validationSchema: schema,
    initialValues: {
      name: data?.name || "",
      type: data?.type || "",
      rarity: data?.rarity || "",
    },
    onSubmit: async () => {
      const formData: any = new FormData();
      formData.append("file", image);
      formData.append("name", values.name);
      formData.append("type", values.type);
      formData.append("rarity", values.rarity);

      //if edit or create
      if (id) {
        mutation.mutate(
          { id, data: formData },
          {
            onSuccess() {
              showMsg({ type: "success", text: "Edited" });
              navigate("/horse");
            },
            onError(err: any) {
              showMsg({ type: "error", text: err.message });
            },
          }
        );
      } else {
        mutation.mutate(formData, {
          onSuccess: async (data) => {
            showMsg({ type: "success", text: "Added" });
            if (contract) {
              console.log(data);
              try {
                await contract.methods
                  .chargeTokenList(data.type, data.id)
                  .send({ from: account });
              } catch (e) {
                console.log(e);
              }
            }
          },
          onError(err: any) {
            showMsg({ type: "error", text: err.message });
          },
        });
      }
    },
  });

  return (
    <>
      <Header
        title={`${id ? "Edit" : "Create"} an entry`}
        buttons={[
          <ButtonRequireWallet
            variant="contained"
            onClick={handleSubmit}
            loading={mutation.isLoading}
          >
            Save
          </ButtonRequireWallet>,
        ]}
      />
      <FormContainer>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box flex={1} sx={{ mr: 1 }}>
            <InputImage
              label="image"
              onChangeValue={(value) => setImage(value)}
              img={data?.image}
            />
          </Box>
          <Box flex={1} sx={{ ml: 1 }}>
            <TextField
              error={touched.name && errors.name !== undefined}
              autoFocus
              margin="dense"
              label="name"
              type="text"
              fullWidth
              value={values.name}
              name="name"
              variant="outlined"
              onChange={handleChange}
              sx={{ minWidth: 300 }}
            />
            <FormControl fullWidth margin="dense">
              <InputLabel id="select-type">Type</InputLabel>
              <Select
                error={touched.type && errors.type !== undefined}
                labelId="select-type"
                fullWidth
                label="Type"
                name="type"
                onChange={handleChange}
                value={values.type}
              >
                {boxes.map((item) => {
                  return (
                    <MenuItem value={item.id} key={item.id}>
                      {item.type}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl fullWidth margin="dense">
              <InputLabel id="select-rarity">Rarity</InputLabel>
              <Select
                labelId="select-rarity"
                fullWidth
                label="Rarity"
                name="rarity"
                onChange={handleChange}
                value={values.rarity}
              >
                {rarityOptions.map((item) => {
                  return (
                    <MenuItem value={item.value} key={item.value}>
                      {item.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        </Box>
      </FormContainer>
    </>
  );
};

export default HorseForm;
