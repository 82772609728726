import { useNavigate } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import { GridColDef } from "@mui/x-data-grid";

import { List } from "common/components";
import useWeb3 from "hooks/useWeb3";

const Web3 = require("web3/dist/web3.min.js");

const AdminBox = () => {
  const navigate = useNavigate();
  const contract = useWeb3();
  const { account } = useWeb3React();

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
    },
    {
      field: "type",
      headerName: "TYPE",
      flex: 1,
    },
  ];

  const rows: any = [
    {
      id: 1,
      type: "Warrior",
    },
  ];

  return (
    <>
      <List title="Info" columns={columns} rows={rows} />
    </>
  );
};

export default AdminBox;
