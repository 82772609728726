import { useState } from "react";
import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box } from "@mui/material";
import { faImage } from "@fortawesome/free-solid-svg-icons";
import { grey } from "@mui/material/colors";

interface Props {
  img?: string;
  label: string;
  onChangeValue: (value: any) => void;
}

const Container = styled.label`
  display: block;
  cursor: pointer;
  > input {
    display: none;
  }
  > span {
    width: 100%;
    height: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: ${grey[200]};
    border-radius: 5px;
  }
`;

const PreviewContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const Preview = styled.div<{ src: string }>`
  ${(props) => `
    width: 100%;
    height: 100%;
    background-image: url(${props.src});
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  `}
`;

const InputImage = ({ img, label, onChangeValue }: Props) => {
  const [image, setImage] = useState<any>(img);

  const onChange = (event: any) => {
    if (event.target.files[0]) {
      const file = event.target.files[0];
      setImage(URL.createObjectURL(file));
      onChangeValue(file);
    }
  };

  return (
    <Container>
      <input
        type="file"
        onChange={onChange}
        accept="image/jpg, image/jpeg, image/png"
      />
      <span>
        {image ? (
          <PreviewContainer>
            <Preview src={image} />
          </PreviewContainer>
        ) : (
          <>
            <FontAwesomeIcon icon={faImage} size="2x" color={grey[500]} />
            <Box sx={{ mt: 2 }}>
              Click to add an asset or drag and drop one in this area
            </Box>
          </>
        )}
      </span>
    </Container>
  );
};

export default InputImage;
