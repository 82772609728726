import { AnyAction, combineReducers } from "redux";

import msgReducers, { MsgState } from "./msg";
import adminReducers, { AdminState } from "./admin";

export interface ReduxState {
  msg: MsgState;
  admin: AdminState;
}

const appReducer = combineReducers({
  msg: msgReducers,
  admin: adminReducers,
});

const reducers = (state: ReduxState | undefined, action: AnyAction) => {
  return appReducer(state, action);
};

export default reducers;
