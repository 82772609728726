import { DataGrid, GridRowsProp, GridColDef } from "@mui/x-data-grid";
import { Paper } from "@mui/material";
import { useNavigate } from "react-router-dom";

import Header from "common/components/Header";
import { ReactNode } from "react";

interface Props {
  title: string;
  columns: GridColDef[];
  rows: GridRowsProp[];
  loading?: boolean;
  creatable?: boolean;
  headerButtons?: ReactNode[];
}

const List = ({
  title,
  rows,
  columns,
  loading,
  creatable,
  headerButtons,
}: Props) => {
  const navigate = useNavigate();

  const navigateToCreate = () => {
    navigate("create");
  };

  return (
    <>
      <Header
        title={title}
        buttons={headerButtons}
        onCreate={creatable ? navigateToCreate : undefined}
        entriesCount={rows?.length || undefined}
      />
      <Paper>
        <DataGrid
          rows={rows || []}
          columns={columns}
          autoHeight
          disableColumnFilter
          disableColumnMenu
          disableColumnSelector
          disableDensitySelector
          disableExtendRowFullWidth
          disableSelectionOnClick
          hideFooter
          loading={loading}
        />
      </Paper>
    </>
  );
};

export default List;
