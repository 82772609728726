import { useEffect, useMemo, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
} from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";

import { ButtonRequireWallet, List } from "common/components";
import useWeb3 from "hooks/useWeb3";
import boxes from "constants/box";
import type { IBox } from "types";

const Web3 = require("web3/dist/web3.min.js");

const BoxList = () => {
  const contract = useWeb3();
  const { account } = useWeb3React();
  const [priceModal, setPriceModal] = useState(false);
  const [prices, setPrices] = useState({
    cyber: "0",
    warrior: "0",
    unicorn: "0",
    dragon: "0",
  });
  const [selectedBox, setSelectedBox] = useState<IBox>();

  useEffect(() => {
    if (contract) {
      initBoxPrices();
    }
  }, [contract]);

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "id",
        headerName: "ID",
      },
      {
        field: "type",
        headerName: "TYPE",
        flex: 1,
      },
      {
        field: "price",
        headerName: "PRICE",
      },
      {
        field: "toolbar",
        headerName: "",
        renderCell: (params: GridRenderCellParams) => {
          return (
            <ButtonRequireWallet
              variant="contained"
              onClick={() => {
                showPriceModal();
                setSelectedBox({
                  ...params.row,
                  price: params.row.price,
                });
              }}
            >
              Set Price
            </ButtonRequireWallet>
          );
        },
      },
    ],
    []
  );

  const initBoxPrices = async () => {
    try {
      const { cyberPrice, warriorPrice, unicornPrice, dragonPrice } =
        await contract?.methods.priceInfo().call();
      setPrices({
        cyber: cyberPrice,
        warrior: warriorPrice,
        unicorn: unicornPrice,
        dragon: dragonPrice,
      });
    } catch (e) {
      console.log(e);
    }
  };

  const onChangePrice = (e: any) => {
    let p: any = { ...selectedBox, price: e.target.value };
    setSelectedBox(p);
  };

  const showPriceModal = () => {
    setPriceModal(true);
  };

  const hidePriceModal = () => {
    setPriceModal(false);
  };

  const onSetPrice = async () => {
    if (selectedBox) {
      console.log(" >>>> account <<<< ", account);
      console.log(" >>>> selectedBox <<<<", selectedBox);
      try {
        hidePriceModal();
        await contract.methods
          .setPrice(
            selectedBox.id,
            Web3.utils.toWei(selectedBox.price!, "ether")
          )
          .send({ from: account });
      } catch (e) {
        console.log(e);
      }
    }
  };

  const rows: any = boxes.map((item) => {
    const price = Web3.utils.fromWei(prices[item.type], "ether");

    return {
      ...item,
      price,
    };
  });

  return (
    <>
      <List title="Box" columns={columns} rows={rows} />
      <Dialog open={priceModal} onClose={hidePriceModal}>
        <DialogTitle>Set Price</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label={selectedBox?.type}
            type="number"
            fullWidth
            variant="outlined"
            onChange={onChangePrice}
            sx={{ minWidth: 300 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={hidePriceModal}>Cancel</Button>
          <Button variant="contained" onClick={onSetPrice}>
            Set Price
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default BoxList;
